exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-docs-android-tsx": () => import("./../../../src/pages/docs/android.tsx" /* webpackChunkName: "component---src-pages-docs-android-tsx" */),
  "component---src-pages-docs-ios-tsx": () => import("./../../../src/pages/docs/ios.tsx" /* webpackChunkName: "component---src-pages-docs-ios-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sdk-tsx": () => import("./../../../src/pages/sdk.tsx" /* webpackChunkName: "component---src-pages-sdk-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

